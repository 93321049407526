import React, { useState } from "react";
import { Button, Card, Col, Divider, Row } from "antd";
import { YoutubeOutlined, ArrowDownOutlined, CloseOutlined } from '@ant-design/icons';
import Image from "../img";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Especializado = (props) => {
  const [videoStr, setVideoStr] = useState(null);
  const [record, setRecord] = useState(null);
  const handleOpenYT = (video) => {
    setVideoStr(video)
  }
  const handleOpenModal = (data) => {
    setRecord(data)
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };

  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };

  return (
    <>
      <div id="serviciotecnico" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Servicio técnico de equipos especiales</h2>
          </div>
          <div className="row">
            <p className='text-center' style={{ fontWeight: 270, fontSize: '18px' }}>
              <div className="row">
                {props.data?.lineaspecial
                  ? props.data.lineaspecial.map((d, i) => (
                    <div key={`${d.name}-${i}`} className="col-md-4" id="services-card">
                      {/* {" "} */}
                      <i className={d.icon}></i>
                      <div className="service-desc">
                        <h3>{d.name}</h3>
                        <p>{d.text}</p>
                      </div>
                    </div>
                  ))
                  : "loading"}
              </div>
            </p>
          </div>
        </div>
      </div>


      {1>2 &&
      <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Conocé la línea de equipos especiales</h2>
          {/* <p>
            Contamos con pleno conocimiento para la puesta en marcha e integración de los equipos especiales
          </p> */}
        </div>
        <div id="row">
          <div className="col-md-12 col-sm-12 team">
            <Carousel
              responsive={responsive}
              infinite
              showDots={false}
              draggable
              swipeable
              // containerClass="custom-dot-list-style"
              // itemClass="image-item"
              autoPlaySpeed={5000}
              autoPlay
              customTransition="all .5"
              transitionDuration={500}
              deviceType='desktop'
              arrows={false}
            // removeArrowOnDeviceType={["desktop"]}
            >
              {props.data?.Team
                ? props.data.Team.map((d, i) => (
                  <Card
                    key={`${d.name}-${i}`}
                    hoverable
                    style={{
                      width: 240,
                    }}
                    cover={<img alt="example" src={d.img} style={{ minHeight: '240px !important', maxHeight: '240px !important' }} />}
                    actions={[
                      <YoutubeOutlined key="youtube" style={{ color: '#ff711a' }} onClick={() => handleOpenYT(d.linkvid)} />,
                      <ArrowDownOutlined key="expand" style={{ color: '#ff711a' }} onClick={() => handleOpenModal(d)} />
                    ]}
                  >
                    <Card.Meta title={d.name} description={d.job} />
                  </Card>

                ))
                : []
              }
            </Carousel>

          </div>
        </div>
        <br />
        <br />
        <div id="row">
          {videoStr &&
            <div className="container">
              <div className="col-md-8 col-md-offset-2 section-title">
                <Button type="link" onClick={() => setVideoStr(null)} style={{ float: 'right' }}><CloseOutlined /></Button>
              </div>
              <br />
              <iframe width="560" height="315" src={videoStr} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          }
        </div>
        <div id="row" style={{ Border: '1px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
          {record?.descripcion &&
            <div className="container">
              <div id="tarjeta">
                <div className="col-md-8 col-md-offset-2 section-title">
                  <Button type="link" onClick={() => setRecord(null)} style={{ float: 'right' }}><CloseOutlined /></Button>
                </div>
                <br />
                <div className="col-md-8 col-md-offset-2 section-title">
                  <p>{record.descripcion}</p>
                  <p>{record.descripcion2}</p>
                  <p>{record.descripcion3}</p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
      }

      <Divider />
      {/* --------------- CLIENTES --------------- */}
      <div id="clientes" className="text-center" style={{ backgroundColor: 'white' }}>
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>CLIENTES QUE CONFÍAN EN NOSOTROS</h2>
          </div>
          <div className="col-md-12 section-title">
            {props?.data?.Ourclients &&
              <Carousel
              swipeable
              draggable
                responsive={responsive2}
                infinite
                arrows={null}
                showDots={false}
                containerClass="custom-dot-list-style"
                itemClass="image-item"
                autoPlaySpeed={5000}
                autoPlay
                customTransition="all .5"
                transitionDuration={500}
                deviceType='desktop'
              // removeArrowOnDeviceType={["desktop"]}
              >
                {props.data.Ourclients.map((d, i) => {
                  return <Image key={i} url={require(`../assets/images/${d.image.src_image}`)} alt={d.image.alt_image} width={'100%'} height={'100%'} />;
                })}
              </Carousel>
            }
          </div>
          <Row>
            <Col span={24}>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
