import React from "react";
import { Grid, Button, Dropdown, Space } from 'antd';

import Logo from "../assets/Icons/logo.png";
import utils from "../utils";

const { useBreakpoint } = Grid;
const texto = "https://api.whatsapp.com/send/?phone=";

export const Navigation = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const items = [
    // {
    //   label: (
    //     <a className="page-scroll" href="#postventa">
    //       Servicio Post Venta
    //     </a>
    //   ),
    //   key: '0',
    //   type: 2
    // },
    {
      label: (
        <a className="page-scroll" href="#marcas">
          Distribuidor Oficial
        </a>
      ),
      key: '1',
      type: 2
    }
  ];
  const items2 = [
    {
      label: (
        <a className="page-scroll" href="#inicio">
          Marcas
        </a>
      ),
      key: '2',
      type: 1
    },
    {
      label: (
        <a className="page-scroll" href="#distribuidoroficial">
          Nuestra Soluciones
        </a>
      ),
      key: '3',
      type: 1
    },
    {
      label: (
        <a className="page-scroll" href="#clientes">
          Clientes
        </a>
      ),
      key: '4',
      type: 1
    },
    {
      label: (
        <a className="page-scroll" href="#portfolio">
          Sobre nosotros
        </a>
      ),
      key: '5',
      type: 1
    }
  ];


  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <a className="page-scroll" href="#page-top">
            <img src={Logo} alt="img" width={65} heigh1t={63} style={{ verticalAlign: 'middle', marginLeft: 'auto', marginRight: 'auto' }} />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
            <a href="#inicio" className="page-scroll">
              <Space>
                Inicio
              </Space>
              </a>
              {/* <Dropdown
                menu={{
                  items: items2,
                }}
              >
                <a href="#inicio" className="page-scroll">
                  <Space>
                    Inicio
                  </Space>
                </a>
              </Dropdown> */}
            </li>
            <li>
                <a href="#soluciones" className="page-scroll" >
                  <Space>
                    Soluciones
                  </Space>
                </a>
            </li>
            <li>
              <a href="#serviciotecnico" className="page-scroll">
                Servicio Técnico Especializado
              </a>
            </li>
            <li>
              <a href="#nosotros" className="page-scroll">
                Nosotros
              </a>
            </li>
            <li>
              {!isMobile && <div className="page-scroll" style={{ marginTop: '12px' }}><Button type="primary" className="page-scroll"
                onClick={() => window.open(`${texto}5493482621794&text=Hola.%20Quiero%20solicitar%20una%20cotizaci%C3%B3n.`)}
              >Contacto</Button> </div>}

            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
