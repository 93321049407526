import React from "react";
import { Row, Col } from "antd";
import { Image as Imagen } from "./image";

export const Nosotros = (props) => {
  return (
    <>
    <div id="nosotros">
      <div className="container">
        <section>
          <div className="row">
            <h2 className='leading-tight text-center wow fadeInDown animated' style={{ marginTop:'100px',textAlign: 'center', fontWeight: 470, fontSize: '30px', color: '#ff711a' }}><b>Sobre nosotros</b></h2>
          </div>
          {/* <div className="row-auto ml-8 mr-8 mt-10"> */}
          <Row>
            {/* <Col span={{ span: { xs: 24, sm: 16, md: 16, lg: 16 } }}> */}
            <Col sm={24} md={24} lg={24} xl={24} xxl={24}>
              <h2 className='text-4xl'><b>DS Ingenieria y Servicios</b></h2>
              <p className="mb-3">
                Somos una empresa de Reconquista, Santa Fe dedicada a dar soluciones integrales y personalizadas en soldadura y corte metalmecánicos. Unimos tecnología, innovación y marcas de primer nivel para acompañar a nuestros clientes a alcanzar su máximo potencial.
                <br />
                Mediante nuestro equipo técnico-comercial especializado evaluamos, diseñamos y ejecutamos como aliados estratégicos del cliente nuevos proyectos de mejora continua, buscando maximizar su productividad.
                <br />
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="vision">
                <h3 className='text-2xl text-center'><b>VISIÓN</b></h3>
                <br />
                {/* Vision: responde a ¿dónde te ves a futuro? Y ¿haciendo qué? */}
                <p className='text-justify'>Ser la principal referencia del norte de nuestro país en los rubros de soldadura y corte metalmecánico, mediante insumos, consumibles, equipos, servicio técnico y proyectos tecnológicos asociados.</p>
              </div>
            </Col>
            <Col sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="vision">
                <h3 className='text-2xl text-center'><b>MISIÓN</b></h3>
                <br />
                {/* ¿Qué haces actualmente? ¿Y cómo? */}
                <p className='text-justify'>Brindar soluciones integrales, innovadoras y personalizadas en soldadura y corte metalmecánicos mediante insumos y tecnología, con un equipo motivado por los desafíos de la organización.</p>
              </div>
            </Col>
            <Col sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="vision">
                <h3 className='text-2xl text-center'><b>VALORES</b></h3>
                <ul>
                  <li><p className='text-justify'><b>• Sinceridad</b></p></li>
                  <li><p className='text-justify'><b>• Compromiso</b></p></li>
                  <li><p className='text-justify'><b>• Profesionalismo</b></p></li>
                  <li><p className='text-justify'><b>• Competitividad</b></p></li>
                  <li><p className='text-justify'><b>• Optimismo</b></p></li>
                  <li><p className='text-justify'><b>• Unión</b></p></li>
                </ul>
              </div>
            </Col>
          </Row>

        </section>
      </div>
    </div>
      {/* --------------- Sobre Nosotros --------------- */}
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Galería</h2>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data?.Gallery
                ? props.data.Gallery.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Imagen
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
